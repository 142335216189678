<template>
  <div class="w-full">
    <SuccessButton
    :disabled="!$atividades.permissoes.includes(14)"
      class="mr-6"
      @func="modalCadastroControle = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar controle"
      @hide="modalCadastroControle = false"
      :active="modalCadastroControle"
      size="small"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="controleName"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="controle.nome"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('controleName')"
              >{{ $validators.empty }}</span
            >
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "ModalCadastroControle",
  data() {
    return {
      controle: {},
      modalCadastroControle: false,
      error: false
    };
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarControle();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrarControle() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.$vs.loading();
          try {
            const res = await this.$http.post("controle", this.controle);
            let logData = {
              id_colaborador: localStorage.getItem("id"),
              funcao: 'cadastrar',
              setor: 'comercial',
              ip: window.localStorage.getItem("ip"),
              texto: 'Cadastro do controle N°' + res.id}
            await this.$logger(logData)

            this.controle = {};
            this.$vs.notify(this.$notify.Success);
            this.modalCadastroControle = false;
          } catch (err) {
            const error = this.$httpErrors(err);
            this.$vs.notify(error);
          } finally {
            await this.$emit("update");
            this.$vs.loading.close();
          }
        }
      });
    }
  },
  components: components
};
</script>

<style></style>
