<template>
  <div>
    <EditButton :disabled="!$atividades.permissoes.includes(14)" @func="modalEditarControle = true"></EditButton>
    <SideBar
      title="Editar controle"
      @hide="cancelar"
      :active="modalEditarControle"
      textSucces="Atualizar"
      icon="edit"
      size="small"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="controle"
      @excluir="excluirControle()"
      @status="mudarStatusControle()"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="nome"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="controle.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarControle",
  props: { controle: Object },
  data() {
    return {
      modalEditarControle: false,
      error: false,
      originalData: {}
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.controle);
    this.originalData = this.controle;
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarControle();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarControle() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`controle/${this.controle.id}`, this.controle);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Atualização do controle ' + res.id}
        await this.$logger(logData)

        this.$vs.notify(this.$notify.Success);
        this.modalEditarControle = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async excluirControle() {
      this.$vs.loading();
      try {
        await this.$http.delete(`controle/${this.controle.id}`);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'excluir',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Exclusão do controle N°' + this.controle.id}
        await this.$logger(logData)

        this.$vs.notify(this.$notify.Success);
        this.modalEditarControle = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusControle() {
      this.$vs.loading();
      try {
        await this.$http.put(`controle/${this.controle.id}`, {
          lixeira: !this.controle.lixeira
        });
        this.$vs.notify(this.$notify.Success);
        this.modalEditarControle = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    cancelar() {
      Object.assign(this.controle, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarControle = false;
    }
  },
  components: components
};
</script>

<style></style>
